import { SelectHTMLAttributes, useMemo } from "react"
import styled from "styled-components"

import ChevronDown from "src/ui/icons/chevron-down-larger.svg"
import {
  InputContainer,
  TInputContainerProps,
} from "src/ui/InputContainer/InputContainer"
import { MText } from "src/ui/MText"

export type TMSelectOption = {
  key?: string
  label: string
  value: string
  disabled?: boolean
  hidden?: boolean
}

type TMSelectGroupOption = {
  key?: string
  title: string
  options: TMSelectOption[]
}

export type TMSelectOptions = (TMSelectOption | TMSelectGroupOption)[]

type THTMLSelectAttributes = Omit<
  SelectHTMLAttributes<HTMLSelectElement>,
  "onChange"
>

type TOmittedInputContainerProps = Omit<
  TInputContainerProps,
  | "shrink"
  | "tabIndex"
  | "endAdornment"
  | "cursor"
  | "children"
  | "showClearButton"
  | "onClear"
>

export type TMSelectProps = THTMLSelectAttributes & {
  options: TMSelectOptions
  value: string
  onChange: (selectedValue: string) => void
  ariaLabel?: string
} & TOmittedInputContainerProps

export function MSelect({
  label,
  options,
  value,
  onChange,
  required,
  error,
  disabled,
  helperText,
  startAdornment,
  ariaLabel,
  requiredIndicator,
  ...props
}: TMSelectProps) {
  const flattenedOptions = useMemo(() => {
    return flattenOptions(options)
  }, [options])

  const selectedLabel = useMemo(() => {
    return (
      flattenedOptions.find((option) => option.value === value)?.label || ""
    )
  }, [flattenedOptions, value])

  const optionsContent = useMemo(() => {
    return options.map((option) => {
      if (isGroup(option)) {
        return (
          <optgroup key={option.key || option.title} label={option.title}>
            {option.options.map((groupedOption) => (
              <option
                key={groupedOption.key || groupedOption.value}
                value={groupedOption.value}
                disabled={groupedOption.disabled}
                hidden={groupedOption.hidden}
              >
                {groupedOption.label}
              </option>
            ))}
          </optgroup>
        )
      }

      return (
        <option
          key={option.key || option.value}
          value={option.value}
          disabled={option.disabled}
          hidden={option.hidden}
        >
          {option.label}
        </option>
      )
    })
  }, [options])

  return (
    <InputContainer
      label={label}
      endAdornment={<ChevronDown width={16} color="unset" />}
      error={error}
      disabled={disabled}
      helperText={helperText}
      startAdornment={startAdornment}
      requiredIndicator={requiredIndicator}
      shrink={!!selectedLabel}
    >
      <div>
        <StyledSelect
          value={value}
          onChange={(e) => {
            onChange(e.target.value)
          }}
          required={required}
          disabled={disabled}
          aria-label={ariaLabel || label}
          aria-errormessage={typeof error === "string" ? error : undefined}
          aria-invalid={!!error}
          {...props}
        >
          {optionsContent}
        </StyledSelect>
        <StyledMText color="unset">{selectedLabel}</StyledMText>
      </div>
    </InputContainer>
  )
}

function isGroup(
  option: TMSelectOption | TMSelectGroupOption
): option is TMSelectGroupOption {
  return "title" in option
}

function flattenOptions(options: (TMSelectOption | TMSelectGroupOption)[]) {
  return options.flatMap((option) => {
    if (isGroup(option)) {
      return option.options
    }

    return option
  })
}

const StyledSelect = styled.select`
  all: unset;
  appearance: none;
  opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledMText = styled(MText)`
  min-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
